/* Instruments */
import { appSettingsSlice, IAppSettingsSliceState } from './slices';

export type RootState = {
  appSettings: IAppSettingsSliceState;
};

export const reducer = {
  appSettings: appSettingsSlice.reducer,
};
