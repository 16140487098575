'use client';

/* Core */
import { Provider } from 'react-redux';
import { reduxStore } from '@/app/lib/redux';
import '@/app/helpers/extensions/index';
import FeatureToggleProvider from '@/app/featuretoggles/FeatureToggleContext';
import { FeatureToggles } from '@/app/featuretoggles/ft';
import React from 'react';

/* Instruments */

interface Props {
  featureToggles: FeatureToggles;
  children: React.ReactNode;
}

export const Providers = (props: Props) => {
  return (
    <Provider store={reduxStore}>
      <FeatureToggleProvider featureToggles={props.featureToggles}>{props.children}</FeatureToggleProvider>
    </Provider>
  );
};
