/* Core */
import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/app/lib/redux/rootReducer';
import { IProduct, MbbUser } from '@mybestbrands/types';

const initialState: IAppSettingsSliceState = {
  mobileFiltersOpen: false,
  detailModalVisible: false,
  authModalVisible: null,
  firstListingProductIds: [],
  voucherData: null,
  pageContent: '',
  eventId: '',
  sizeData: false,
  pNcDisclaimer: false,
  promoHeadLine: '',
  productImpressions: [],
  user: null,
};

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    toggleMobileFiltersOpen: (state, action: PayloadAction<boolean>) => {
      state.mobileFiltersOpen = action.payload;
    },
    toggleDetailModalVisible: (state, action: PayloadAction<boolean>) => {
      state.detailModalVisible = action.payload;
    },
    toggleAuthModalVisible: (state, action: PayloadAction<string | null>) => {
      state.authModalVisible = action.payload;
    },
    setFirstListingProductIds: (state, action: PayloadAction<string[]>) => {
      state.firstListingProductIds = action.payload;
    },
    setPageContent: (state, action: PayloadAction<string>) => {
      state.pageContent = action.payload;
    },
    setEventId: (state, action: PayloadAction<string>) => {
      state.eventId = action.payload;
    },
    setVoucherData: (state, action: PayloadAction<IProduct | null>) => {
      state.voucherData = action.payload;
    },
    setSizeData: (state, action: PayloadAction<boolean>) => {
      state.sizeData = action.payload;
    },
    setPNcDisclaimer: (state, action: PayloadAction<boolean>) => {
      state.pNcDisclaimer = action.payload;
    },
    setPromotionHeadline: (state, action: PayloadAction<string>) => {
      state.promoHeadLine = action.payload;
    },
    setProductImpressions: (state, action: PayloadAction<string | null>) => {
      if (action.payload === null) {
        state.productImpressions = [];
      } else {
        state.productImpressions = [...state.productImpressions, action.payload];
      }
    },
    setUser: (state, action: PayloadAction<MbbUser | null>) => {
      state.user = action.payload;
    },
  },
});

export const {
  setEventId,
  toggleDetailModalVisible,
  toggleAuthModalVisible,
  setPageContent,
  setFirstListingProductIds,
  setVoucherData,
  setSizeData,
  setPNcDisclaimer,
  setPromotionHeadline,
  setProductImpressions,
  setUser,
} = appSettingsSlice.actions;

export const appSettingsState = (state: RootState): IAppSettingsSliceState => state.appSettings;

/* Types */
export interface IAppSettingsSliceState {
  mobileFiltersOpen: boolean;
  detailModalVisible: boolean;
  authModalVisible: string | null;
  firstListingProductIds: string[];
  pageContent: string;
  eventId: string;
  promoHeadLine: string;
  voucherData: IProduct | null;
  sizeData: boolean;
  pNcDisclaimer: boolean;
  productImpressions: string[];
  user: MbbUser | null;
}
