'use client';
import React, { createContext, ReactNode, useContext, useState } from 'react';
import { FeatureFlagsKeys, FeatureToggles, intialFeatureToggles } from '@/app/featuretoggles/ft';

interface FeatureToggleContextType {
  featureFlags: FeatureToggles;
  toggleFeature: (feature: FeatureFlagsKeys) => void;
}

const FeatureToggleContext = createContext<FeatureToggleContextType>({
  featureFlags: intialFeatureToggles,
  toggleFeature: () => {},
});

interface Props {
  children: ReactNode;
  featureToggles?: FeatureToggles;
}

export default function FeatureToggleProvider({ children, featureToggles }: Props) {
  const [featureFlags, setFeatureFlags] = useState<FeatureToggles>({ ...intialFeatureToggles, ...featureToggles });

  // useEffect(() => {
  //   let update = false;
  //   const currentFeatureFlags = { ...featureFlags };
  //
  //   const allCookies = getCookies();
  //
  //   Object.entries(featureFlags).forEach(([key]) => {
  //     if (`FT_${key}` in allCookies) {
  //       currentFeatureFlags[key as FeatureFlagsKeys] = allCookies[`FT_${key}`] === 'true';
  //       update = true;
  //     }
  //   });
  //
  //   if (update) setFeatureFlags(currentFeatureFlags);
  // }, []);

  const toggleFeature = (feature: FeatureFlagsKeys) => {
    setFeatureFlags((prevFlags) => ({
      ...prevFlags,
      [feature]: !prevFlags[feature],
    }));
  };

  return (
    <FeatureToggleContext.Provider value={{ featureFlags, toggleFeature }}>{children}</FeatureToggleContext.Provider>
  );
}

export const useFeatureToggle = () => useContext(FeatureToggleContext);
