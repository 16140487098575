String.prototype.capitalizeWords = function () {
  return this.split(' ')
    .filter((x) => x)
    .map((x) => x[0].toUpperCase() + x.substring(1).toLowerCase())
    .join(' ');
};

String.prototype.toSpecialCharacters = function (locale: string) {
  if (locale === 'de') return this.replace(/ae/g, 'ä').replace(/ue/g, 'ü').replace(/oe/g, 'ö');
  return this.toString();
};

String.prototype.safeJsonParse = function () {
  try {
    return JSON.parse(this.toString());
  } catch (e) {
    return {};
  }
};
